$color-primary: #650F40;
$color-light: #95175e;
$color-secondary: #E5A400;
$color-secondary-dark: #111111;
$color-muted: #777;
$color-white: #fff;

$font-title: Laca Bold;
$font-primary: 'Roboto', sans-serif;
$font-secondary: Laca Book Italic;

$header-image: "assets/img/header/bg-brand.png";
$fundo-image: "assets/img/header/fundo.png";


@import "agency";
@import "header";
@import "fundo";
@import "rating";
@import "innovation";
@import "contact";
@import "about";
