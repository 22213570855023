#contact {
  background: url('assets/img/contact/background-contact.png') no-repeat top;
  background-size: contain;
}

#contact section {
  padding: 0;
}

#contact .card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 10px;
}

@media (min-width: 1400px) {
  #contact .card {
    padding: 6rem 6rem;
    margin-top: 5rem;
  }
}

#contact .contact-info-item {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

#contact .contact-info-item img {
  width: 3rem;
  margin-right: 1rem;
}

#contact .contact-info-item span {
  color: #777;
  font-size: 2rem;
}

/* Ajustes para os ícones das redes sociais na seção de contato */
#contact .contact-socials a {
  margin-right: 1.5rem;
}

#contact .contact-socials a img {
  width: 3rem;
}

.flex-align {
  display: flex;
  align-items: center; /* Alinha os itens verticalmente no centro */
  height: 100%;
}

.flex-align .col {
  flex: 1; /* Assegura que cada coluna ocupe espaço igual */
}

@media (max-width: 990px) {
  #contact .contact-info-item img {
    width: 2.3rem;
    margin-right: 0.8rem;
  }
  
  #contact .contact-info-item span {
    color: #777;
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .flex-align {
    display: block;
    align-items: center; /* Alinha os itens verticalmente no centro */
    height: 100%;
  }

}


@media (max-width: 490px) {
  #contact .contact-info-item img {
    width: 2rem;
    margin-right: 0.7rem;
  }
  
  #contact .contact-info-item span {
    color: #777;
    font-size: 1.5rem;
  }
}