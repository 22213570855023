.carousel-container {
  position: relative;
  background-color: transparent; // Fundo transparente para o container principal
  padding: 20px;
  border-radius: 10px;
  overflow: hidden; // Para garantir que o pseudo-elemento não exceda os limites do container
  // z-index: -1;
}

.carousel-container::before {
  content: '';
  position: absolute;
  left: 30%; // Começa no meio do círculo
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $color-primary; // Cor de fundo desejada
  // border-top-right-radius: 10px; // Arredonda os cantos direitos
  // border-bottom-right-radius: 10px;
  border-radius: 15px;
  z-index: -2; // Garante que fique atrás do conteúdo
}


.carousel-card {
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;  // Padrão para desktop
  grid-template-areas: "prev content next";  // Áreas nomeadas para desktop
  grid-template-rows: auto;
  padding: 4rem 2rem;
  height: 35vh;
  min-height: 350px;
}

.circle-container {
  width: 250px;
  height: 250px; // Ajustar conforme necessário
  border-radius: 50%;
  overflow: hidden;
  border: 6px solid $color-secondary;
  padding: 0;
  z-index: 1;
}

.circle-img {
  width: 100%;
  height: auto;
}

.timeline-divider {
  height: 5px;
  background-color: $color-secondary;
  position: relative;
  width: 400%; // Considerando padding do card
  left: -150%; // Largura do círculo + padding
  top: 35%; // Ajustar conforme a posição vertical desejada
  margin: 0; // Metade da altura para centralizar
  padding: 0;
  z-index: 0;
}

.timeline-first {
  left: -20% !important;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 5px;
  background-color: $color-muted;
  border-radius: 50%;
  cursor: pointer;
}

.carousel-indicators li.active {
  background-color: $color-secondary;
  margin: 5px // Muda a cor para destacar o indicador ativo
}

.navigation-btn-container {
  display: flex;
  align-items: center;
  height: 100%; // Assegura que a coluna ocupe toda a altura do card
}

.navigation-btn-container.left {
  justify-content: flex-start; // Alinha o ícone à esquerda

}

.navigation-btn-container.right {
  justify-content: flex-end; // Alinha o ícone à direita
}

.btn-prev, .btn-next {
  background: none; // Remove o fundo
  border: none; // Remove a borda
  color: $color-secondary; // Define a cor para branco
  cursor: pointer; // Assegura que o cursor indique clicabilidade
  font-size: 5rem !important; // Aumenta o tamanho do ícone
  padding: 10px; // Espaço para facilitar o clique
  z-index: 1;
}

.btn-prev:hover, .btn-next:hover {
  color: $color-muted; // Muda a cor ao passar o mouse para dar feedback visual
}

.mt-15 {
  margin-top: 1.5rem;
}

.mb-0 {
  margin-bottom: 0;
}

.card-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 4fr;
  grid-template-areas: 
  "image title"  
  "image divider" 
  "image texto";  
  column-gap: 2rem;
}

.carousel-card .next{
  grid-area: next;
}
.carousel-card .prev{
  grid-area: prev;
}
.carousel-card .content{
  grid-area: content;
}

.card-content .image{
  grid-area: image;
}

.card-content .title{
  grid-area: title;
}

.card-content .divider{
  grid-area: divider;
}

.card-content .texto{
  grid-area: texto;
}

@media (max-width: 991px) {
  .carousel-card {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;  // Padrão para desktop
    grid-template-areas: "prev content next";  // Áreas nomeadas para desktop
    grid-template-rows: auto;
    padding: 2rem 1rem;
    height: 35vh;
    min-height: 350px;
  }

  .circle-container {
    width: 18rem;
    height: 18rem; // Ajustar conforme necessário
    border-radius: 50%;
    overflow: hidden;
    border: 6px solid $color-secondary;
    padding: 0;
  }

  .title h3{
    font-size: 2.1rem;
  }  
  
  .texto p{
    font-size: 1.6rem;
  }

  .btn-prev, .btn-next {
      font-size: 3rem !important; /* Tamanho menor para mobile */
  }
}

@media (max-width: 600px) {
  .card-content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "image title"   /* Cabeçalho ocupa as duas colunas */
    "image divider" /* Sidebar à esquerda, conteúdo à direita */
    "texto texto"    
    "texto texto";  
    // align-items: center;
    column-gap: 1rem;
  }

  .carousel-container {
    position: relative;
    background-color: $color-primary; // Fundo transparente para o container principal
    padding: 1rem;
  }

  .carousel-container::before {
    display: block;
  }

  .carousel-card {
    display: grid;
    grid-template-columns: 1fr 1fr;  // Padrão para desktop
    grid-template-areas: 
    "content content" 
    "content content"  
    "content content"  
    "prev next";  
    grid-template-rows: auto;
    padding: 2rem 1rem;
    height: 35vh;
    min-height: 350px;
  }

  .circle-container {
    width: 15rem;
    height: 15rem; // Ajustar conforme necessário
    border-radius: 50%;
    overflow: hidden;
    border: 6px solid $color-secondary;
    padding: 0;
  }

  .timeline-divider {
    top: -30%;
  }

  .title h3{
    font-size: 2rem;
    margin-top: 3rem;
  }  
  
  .texto p{
    font-size: 1.5rem;
    margin-bottom: 0;
    padding: 1rem;
  }

  .btn-prev, .btn-next {
      font-size: 2.5rem !important; /* Tamanho menor para mobile */
  }
}

@media (max-width: 413px) {
  .carousel-card {
    height: 35vh;
    min-height: 380px;
  }

  .circle-container {
    width: 14rem;
    height: 14rem;
  }
}