

 body {
    overflow-x: hidden;
    font-family: $font-primary;
    font-weight: 400;
    font-style: normal;
    color: $color-secondary-dark;
}

.text-muted {
    color: $color-muted;
    // color: #95175e;
}

.text-primary {
    color: $color-primary;
}

p {
    font-size: 18px;
    line-height: 1.75;
}

p.large {
    font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
}

a {
    color: $color-primary;
}

a:hover,
a:focus,
a:active,
a.active {
    color: $color-secondary;
}

h1, h2, h3, h4, h5, h6 {
    /* text-transform: uppercase; */
    font-family: $font-title;
    font-weight: 500;
}

h4,.h4 {
    font-size: 22px
}

.img-centered {
    margin: 0 auto;
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.bg-darkest-gray {
    background-color: #222;
}

.btn-primary {
    border-color: transparent;
    text-transform: uppercase;
    font-family: $font-title;
    font-weight: 700;
    color: #fff;
    background-color: $color-primary;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    /* border-color: $color-primary; */
    border-color: transparent;
    color: #fff;
    background-color: $color-secondary;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
btn-primary.disabled:focus,
btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
btn-primary.disabled:active,
btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
btn-primary.disabled.active,
btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    /* border-color: $color-primary; */
    border-color: transparent;
    background-color: $color-primary;
}

.btn-primary .badge {
    color: $color-primary;
    background-color: #fff;
}

.btn-xl {
    padding: 20px 40px;
    /* border-color: $color-primary; */
    border-color: transparent;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-title;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: $color-primary;
}

@media (max-width: 768px) {
    .btn-xl {
        padding: 15px 25px; /* Ajuste esses valores conforme necessário */
        font-size: 16px; /* Opcional: ajustar o tamanho da fonte para dispositivos móveis */
    }
}

.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    /* border-color: $color-primary; */
    border-color: transparent;
    color: #fff;
    background-color: $color-secondary;
}

.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-image: none;
}

.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
btn-xl.disabled:hover,
btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
btn-xl.disabled:focus,
btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
btn-xl.disabled:active,
btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
btn-xl.disabled.active,
btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
    /* border-color: $color-primary; */
    border-color: transparent;
    background-color: $color-primary;
}

.btn-xl .badge {
    color: $color-primary;
    background-color: #fff;
}

.navbar-default {
    border-color: transparent;
    -webkit-transition: background-color .3s;
    -moz-transition: background-color .3s;
    transition: background-color .3s;
    // background-color: #222;
    background-color: $color-primary;
}

.navbar-default .navbar-brand {
    font-family: "Kaushan Script", "Helvetica Neue", Helvetica, Arial, cursive;
    color: $color-primary;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
    color: $color-secondary;
}

.navbar-default .navbar-collapse {
    border-color: rgba(255, 255, 255, .02);
}

.navbar-default .navbar-toggle {
    border-color: $color-secondary;
    background-color: $color-secondary;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: $color-primary;
}

.navbar-default .nav li a {
    text-transform: uppercase;
    font-family: "Laca Medium";
    font-weight: normal;
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
    -webkit-transition: all ease .35s;
    -moz-transition: all ease .35s;
    transition: all ease .35s;
}

.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
    outline: 0;
    color: $color-secondary;
}

.navbar-default .navbar-nav>.active>a {
    border-radius: 0;
    color: #fff;
    background-color: $color-primary;
}

.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: $color-secondary;
}

.navbar-brand img {
    max-height: 40px;
    width: auto;
    margin: -7px 0;
}

.navbar-brand .logo-mobile {
    display: none;
}

.navbar-brand {
    position: relative;
}

.navbar-brand::before {
    content: "";
    position: absolute;
    top: -40px; /* Ajuste conforme necessário */
    left: -15px; /* Ajuste conforme necessário */
    right: -15px; /* Ajuste conforme necessário */
    bottom: -20px; /* Ajuste conforme necessário */
    background-color: $color-primary; /* Exemplo de gradiente */
    z-index: -1;
    // border-radius: 0 0 0 3vw;
    border-radius: 0 0 0 3vw;
}

/* Classe para modificar ou anular o ::before baseado no scroll */
.navbar-brand.no-before::before {
    content: none; /* Isso remove o pseudo-elemento */
    /* Ou ajuste outros estilos conforme desejado */
}


@media (max-width: 1200px) {
    .navbar-brand .logo-desktop {
        display: none;
    }

    .navbar-brand .logo-mobile {
        display: inline-block;
    }

    .navbar-brand img {
        max-height: 30px;
    }

}

@media (min-width: 768px) {
    .navbar-default {
        padding: 25px 0;
        border: 0;
        background-color: transparent;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
    }

    .navbar-default .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }

    .navbar-default .navbar-nav>.active>a {
        border-radius: 3px;
    }

    .navbar-default.navbar-shrink {
        padding: 10px 0;
        background-color: $color-primary;
    }

    .navbar-default.navbar-shrink .navbar-brand {
        font-size: 1.5em;
    }
}

@media (max-width: 768px) {
    .navbar-brand::before {
        /* Remove o efeito definindo o conteúdo como vazio e removendo estilos de posicionamento */
        content: none; /* Usar 'none' em vez de "" para remover efetivamente o pseudo-elemento */
        position: static;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        background: none;
    }
}

section {
    padding: 50px 0;
}

section h2.section-heading {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 40px;
}

section h3.section-subheading {
    margin-bottom: 25px;
    text-transform: none;
    font-family: $font-secondary;
    font-style: italic;
    font-weight: 400;
}

section h4.section-subheading-two {
    margin-bottom: 25px;
    text-transform: none;
    font-family: "Laca Regular";
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25;
}

@media(min-width:768px) {
    section {
        padding: 100px 0 50px;
    }
}

.service-heading {
    margin: 15px 0;
    text-transform: none;
}

#portfolio .portfolio-item {
    right: 0;
    margin: 0 0 15px;
}

#portfolio .portfolio-item .portfolio-link {
    display: block;
    position: relative;
    margin: 0 auto;
    max-width: 400px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    transition: all ease .5s;
    background: rgba(254, 209, 54, .9);
    // background: rgba({{ site.data.template.color.primary | hex_to_rgb | join: ',' }}, .9);
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover:hover {
    opacity: 1;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20px;
    margin-top: -12px;
    text-align: center;
    font-size: 20px;
    color: #fff;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content i {
    margin-top: -12px;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h3,
#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content h4 {
    margin: 0;
}

#portfolio .portfolio-item .portfolio-caption {
    margin: 0 auto;
    padding: 25px;
    max-width: 400px;
    text-align: center;
    background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption h4 {
    margin: 0;
    text-transform: none;
}

#portfolio .portfolio-item .portfolio-caption p {
    margin: 0;
    font-family: $font-secondary;
    font-size: 16px;
    font-style: italic;
}

#portfolio * {
    z-index: 2;
}

@media(min-width:767px) {
    #portfolio .portfolio-item {
        margin: 0 0 30px;
    }
}

.timeline {
    position: relative;
    padding: 0;
    list-style: none;
}

.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    background-color: #f1f1f1;
}

.timeline>li {
    position: relative;
    margin-bottom: 50px;
    min-height: 50px;
}

.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}

.timeline>li:after {
    clear: both;
}

.timeline>li .timeline-panel {
    float: right;
    position: relative;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li .timeline-image {
    z-index: 100;
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    border: 7px solid #f1f1f1;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    background-color: $color-primary;
}

.timeline>li .timeline-image h4 {
    margin-top: 12px;
    font-size: 10px;
    line-height: 14px;
}

.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}

.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}

.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}

.timeline>li:last-child {
    margin-bottom: 0;
}

.timeline .timeline-heading h4 {
    margin-top: 0;
    color: inherit;
}

.timeline .timeline-heading h4.subheading {
    text-transform: none;
}

.timeline .timeline-body>p,
.timeline .timeline-body>ul {
    margin-bottom: 0;
}

@media(min-width:768px) {
    .timeline:before {
        left: 50%;
    }

    .timeline>li {
        margin-bottom: 100px;
        min-height: 100px;
    }

    .timeline>li .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right;
    }

    .timeline>li .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left;
    }
}

@media(min-width:992px) {
    .timeline>li {
        min-height: 150px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px;
    }

    .timeline>li .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 30px;
        font-size: 22px;
        line-height: 26px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 20px 20px;
    }
}

@media(min-width:1200px) {
    .timeline>li {
        min-height: 170px;
    }

    .timeline>li .timeline-panel {
        padding: 0 20px 20px 100px;
    }

    .timeline>li .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;
    }

    .timeline>li .timeline-image h4 {
        margin-top: 40px;
    }

    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 100px 20px 20px;
    }
}

.team-member {
    margin-bottom: 50px;
    text-align: center;
}

.team-member img {
    margin: 0 auto;
    border: 7px solid #fff;
}

.team-member h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
}

.team-member p {
    margin-top: 0;
}

aside.clients img {
    margin: 50px auto;
}

footer {
    padding: 25px 0;
    text-align: center;
}

footer span.copyright {
    text-transform: none;
    font-family: $font-title;
    line-height: 40px;
}

footer ul.quicklinks {
    margin-bottom: 0;
    text-transform: none;
    font-family: $font-primary;
    line-height: 40px;
}

ul.social-buttons {
    margin-bottom: 0;
}

ul.social-buttons li a {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 40px;
    outline: 0;
    color: #fff;
    background-color: $color-primary;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
    background-color: $color-primary;
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: 0;
}
.portfolio-modal .modal-content {
    padding: 100px 0;
    min-height: 100%;
    border: 0;
    border-radius: 0;
    text-align: center;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.portfolio-modal .modal-content h2 {
    margin-bottom: 15px;
    font-size: 3em;
}

.portfolio-modal .modal-content p {
    margin-bottom: 30px;
}

.portfolio-modal .modal-content p.item-intro {
    margin: 20px 0 30px;
    font-family: $font-secondary;
    font-size: 16px;
    font-style: italic;
}

.portfolio-modal .modal-content ul.list-inline {
    margin-top: 0;
    margin-bottom: 30px;
}

.portfolio-modal .modal-content img {
    margin-bottom: 30px;
}

.portfolio-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    cursor: pointer;
}

.portfolio-modal .close-modal:hover {
    opacity: .3;
}

.portfolio-modal .close-modal .lr {
    z-index: 1051;
    width: 1px;
    height: 75px;
    margin-left: 35px;
    background-color: #222;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.portfolio-modal .close-modal .lr .rl {
    z-index: 1052;
    width: 1px;
    height: 75px;
    background-color: #222;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

::-moz-selection {
    text-shadow: none;
    background: $color-primary;
}

::selection {
    text-shadow: none;
    background: $color-primary;
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}

body {
    webkit-tap-highlight-color: $color-primary;
}

.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
    transition: background-color 0.5s ease;
}

.whatsapp-float:hover {
    background-color: #128C7E;
}

.whatsapp-float img {
    width: 100%;
    height: auto;
}

.custom-margin-bottom {
    margin-bottom: 30px;
}

.color-white {
    color: #FFF;
}

.color-primary {
    color: $color-primary;
}

.terms-content {
    padding: 20px 0;
    font-size: 16px;
    line-height: 1.5;
}

.terms-wrapper {
    max-width: 60%;
    margin: 0 auto;
    padding: 0 20px;
    font-family: $font-primary; /* Usando a fonte do site */
}

.header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
}

.terms-title {
    text-align: right;
    color: $color-primary; /* Aplicando a cor primária ao título */
}

.terms-content h2 {
    font-family: $font-title;
    color: $color-primary; /* Cor primária para o título H2 */
}

.terms-content strong {
    font-family: $font-title;
    color: $color-primary; /* Cor primária para texto em negrito */
}

.terms-content p {
    font-size: 18px;
    line-height: 1.75;
}

.terms-content p.large {
    font-size: 16px;
}

.logo-page {
    width: 300px;
  }

@media (max-width: 820px) {
    .terms-wrapper {
        max-width: 85%;
        padding: 0 15px;
    }

    .header-section {
        padding-top: 15px;
    }

    .terms-title{
        font-size: 25px;
    }

    .terms-content p {
        font-size: 16px;
        line-height: 1.50;
    }

    .terms-content h2 {
        font-size: 25px;
    }

    .logo-page {
        width: 250px;
      }
}

@media (max-width: 480px) {
    .terms-wrapper {
        max-width: 90%;
        padding: 0 10px;
    }

    .header-section {
        padding-top: 10px;
    }

    .terms-title {
        font-size: 22px;
    }
    
    .logo-page {
        width: 200px;
    }
}

.icon-small {
    font-size: 24px; /* Pequeno */
}

.icon-medium {
    font-size: 48px; /* Médio */
}

.icon-large {
    font-size: 78px; /* Grande */
}

.custom-padding {
    padding-left: 25px;
    padding-right: 25px;
}

.footer-sentence {
    font-family: $font-title;
    font-size: 1.6rem;
    color: $color-primary;
}

.bg-gradient-primary-to-secondary {
    background: linear-gradient(45deg, $color-primary, $color-primary 70%, $color-light 90%);
    padding: 40px 0;
}

.aside-yellow {
    color: $color-secondary;
    font-family: $font-title;
    font-size: 4.8rem;
}

.aside-p {
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;
}

.aside-strong {
    font-size: 4rem;
    font-weight: 800;
    font-style: normal;
}

.mb-1 {
    margin: 0.4rem;
}

#aside-sentence p{
    line-height: 1.1;
}

.px-aside {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

/* Media queries para telas menores */
@media (max-width: 992px) {
    .aside-yellow {
        font-size: 4rem;
    }

    .aside-p {
        font-size: 3rem;
    }
    
    .aside-strong {
        font-size: 3rem;
    }
}

@media (max-width: 768px) {
    .aside-yellow {
        font-size: 3.5rem; 
    }

    .aside-p {
        font-size: 2.5rem;
    }
    
    .aside-strong {
        font-size: 2.5rem;
    }
}

.px-20 {
    padding: 20px 30px!important;
}

@media (max-width: 400px) {
    .px-aside {
        padding-right: 2rem !important;
        padding-left: 2rem !important;
    }
}