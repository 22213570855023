#innovation {
  background-size: contain;
  background-color: transparent;
  background-image: linear-gradient(310deg,  #E0E0E0 10% , #ffffff 100%);
  padding: 50px 0;
}

.device-wrapper {
  max-width: 300px;
  width: 100%;
}

.device {
  position: relative;
  background-size: cover;
}

.device::after {
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.device .screen {
  position: absolute;
  background-size: cover;
  pointer-events: auto;
}

.device .button {
  position: absolute;
  cursor: pointer;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] {
  padding-bottom: 198.898071625%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .screen {
  top: 3.1855955679%;
  left: 6.8870523416%;
  width: 86.2258953168%;
  height: 93.6288088643%;
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black]::after {
  content: "";
  background-image: url("../assets/img/innovation/portrait_black.png");
}

.device[data-device=iPhoneX][data-orientation=portrait][data-color=black] .button {
  display: none;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
}

.masthead {
  background-color: #f8f9fa;
  padding-top: 9.5rem;
  padding-bottom: 5rem;
}

.masthead .masthead-device-mockup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.masthead .masthead-device-mockup .circle {
  position: absolute;
  fill: url(#circleGradient);
  width: 70%;
  display: none;
}

.masthead .masthead-device-mockup .device-wrapper {
  max-width: 10rem;
}

.masthead-device-mockup {
  height: 60vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  /* Isso garante que o círculo fique atrás do dispositivo */
  width: 70%;
  /* Ajuste conforme necessário */
  height: auto;
  /* Mantém a proporção */
}

.device-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 250px;
  /* Ajuste conforme o tamanho desejado */
  z-index: 2;
  /* Isso coloca o dispositivo na frente do círculo */
}

.container-innovation {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1400px) {
  .container-innovation {
    max-width: 1320px;
  }
}

.row-flex {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.app-badge {
  height: 6rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.align-items-center {
  align-items: center !important;
}

.text-lg-start {
  text-align: left !important;
}

.mb-lg-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.flex-column {
  flex-direction: column;
}

.d-flex {
  display: flex;
}

.flex-lg-row {
  flex-direction: row;
  justify-content: center;
}

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.4;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.lh-1 {
  line-height: 1 !important;
}

.me-lg-3 {
  margin-right: 1rem !important;
}


@media (max-width: 990px) {
  .display-1 {
    font-size: 4rem; 
  }
  .app-badge {
    height: 5rem; 
  }

  .circle {
    width: 80%; 
  }

  .device-wrapper {
    width: 60%; 
  }

  .masthead-device-mockup {
    height: 45vh;
  }

}

@media (max-width: 768px) {
  .display-1 {
    font-size: 3.5rem; 
  }

  #innovation p{
    font-size: 1.6rem;
  }

  .app-badge {
    height: 5rem; 
  }

  .flex-lg-row {
    justify-content: space-evenly;
  }

  .circle {
    width: 65%;
  }

  .device-wrapper {
    width: 50%; 
  }

  .row-flex {
    display: block;
  }

  .masthead-device-mockup {
    height: 50vh;
  }
}

@media (max-width: 429px) {
  .app-badge {
    height: 4rem; 
  }

  .masthead-device-mockup {
    height: 40vh;
  }
}




