/* Estilos Gerais do Cabeçalho */
#novo-fundo-secao {
  text-align: left;
  color: #fff;
  background-attachment: scroll;
  background-image: url($fundo-image);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  min-height: 75vh; /* Use min-height ao invés de height fixa */
  padding-top: 10rem;
}

/* Sobreposição (Overlay) */
#novo-fundo-secao::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(101, 15, 64, 0.2); /* Tom suave de #650F40 */
  z-index: 0;
}

/* Estilos do Texto dentro do Cabeçalho */
#novo-fundo-secao .intro-text {
  position: relative;
  z-index: 1; /* O texto ficará acima da sobreposição */
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Retângulo Branco para o Texto */
#novo-fundo-secao .retangulo-branco {
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Retângulo Branco com transparência */
  padding: 4rem;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Ajustar para coincidir com a largura do menu */
  width: 100%;
  margin: 0 auto;
  text-align: left; /* Justificado à esquerda */
  display: flex;
  flex-direction: column; /* Alinha os itens verticalmente */
  align-items: center; /* Centraliza os itens horizontalmente */
}

/* Estilos do Título */
#novo-fundo-secao .intro-heading {
  margin-bottom: 1rem;
  font-size: 4rem;
  font-weight: 700;
  color: #650f40;
}

/* Estilos do Parágrafo */
#novo-fundo-secao .intro-paragraph {
  font-size: 2rem; /* Responsivo para telas pequenas */
  color: #333;
  margin-bottom: 1rem;
  text-align: justify; /* Texto justificado */
  width: 100%;
}

/* Estilos para Strong (Negrito em Preto) */
#novo-fundo-secao .intro-paragraph strong {
  font-weight: bold;
  color: #000;
}

/* Centralização do Botão */
#novo-fundo-secao .retangulo-branco {
  align-items: center; /* Centraliza o conteúdo */
}

/* Botão Customizado */
.btn-custom {
  padding: 20px 40px; /* Mantém o tamanho do .btn-xl */
  border-color: transparent; /* Sem borda visível */
  border-radius: 3px; /* Arredondamento das bordas */
  text-transform: uppercase; /* Texto em maiúsculas */
  font-family: $font-title; /* Utiliza a variável de fonte */
  font-size: 18px; /* Tamanho da fonte */
  font-weight: 700; /* Peso da fonte */
  color: #fff; /* Cor do texto */
  background-color: $color-secondary; /* Cor de fundo diferente */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para efeito de profundidade */
  transition: transform 0.2s, box-shadow 0.2s; /* Transição suave para hover */
  text-align: center;
  display: inline-block;
  margin-top: 2rem; /* Ajuste para espaçar o botão do conteúdo acima */
}

/* Ajustes para Telas Pequenas */
@media (max-width: 768px) {
  .btn-custom {
    padding: 15px 25px; /* Ajuste esses valores conforme necessário */
    font-size: 16px; /* Ajuste do tamanho da fonte para dispositivos móveis */
  }

  #novo-fundo-secao .intro-heading {
    margin-bottom: 0.8rem;
    font-size: 3rem;
    font-weight: 700;
    color: #650f40;
  }

  /* Estilos do Parágrafo */
  #novo-fundo-secao .intro-paragraph {
    font-size: 1.6rem; /* Responsivo para telas pequenas */
    color: #333;
    margin-bottom: 0.8rem;
    text-align: justify; /* Texto justificado */
    width: 100%;
  }
}

/* Efeitos ao passar o mouse, focar e ativar */
.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active {
  background-color: $color-primary;
  color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra maior para efeito "levantado" */
}

/* Estado ativo específico */
.btn-custom:active,
.btn-custom.active {
  transform: translateY(0); /* Retorna ao estado normal após o clique */
}

/* Estados desabilitados e especiais */
.btn-custom.disabled,
.btn-custom[disabled],
fieldset[disabled] .btn-custom,
.btn-custom.disabled:hover,
.btn-custom[disabled]:hover,
fieldset[disabled] .btn-custom:hover,
.btn-custom.disabled:focus,
.btn-custom[disabled]:focus,
fieldset[disabled] .btn-custom:focus,
.btn-custom.disabled:active,
.btn-custom[disabled]:active,
fieldset[disabled] .btn-custom:active,
.btn-custom.disabled.active,
.btn-custom[disabled].active,
fieldset[disabled] .btn-custom.active {
  background-color: $color-secondary; /* Mantém a cor de fundo */
  box-shadow: none; /* Remove a sombra */
  color: #ccc; /* Altera a cor do texto para indicar estado não interativo */
}

.bricks--page {
  width: 100%;
}

.bricks--section {
  width: 100%;

  > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
  }
}

.bricks--row {
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: 768px) {
    flex-flow: row nowrap;
  }
}

.bricks--column > div {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bricks--component-box {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.bricks-form {
  height: auto;
  width: 100%;
}

.bricks-form__fieldset {
  display: flex;
  flex-flow: row wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.bricks-form__field {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;

  &--hidden {
    display: none;
  }

  &__option .bricks-form__label {
    padding-left: 1.33em;
    margin-bottom: 0.2em;

    input[type="checkbox"],
    input[type="radio"] {
      margin: 0px 0px 0px -1.33em;
    }
  }
}

.bricks-form__label {
  position: relative !important;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.4em;
  line-height: 1.25;
  vertical-align: middle;
}

.bricks-form__input {
  display: block;
  width: 100%;
  padding: 0 0.5em;
  line-height: 1.42857;

  &.error {
    border: 1px solid red !important;
  }
}

.bricks-form__submit {
  position: relative;
}

.bricks--component-video div {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  line-height: 0;
  box-sizing: border-box;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.bricks--component-text {
  color: #333;
  line-height: 1.42;
  overflow: hidden;
  word-wrap: break-word;

  a {
    color: #31a2ff;
    text-decoration: none;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;

    sub {
      bottom: -0.25em;
    }

    sup {
      top: -0.5em;
    }
  }

  ol,
  ul {
    padding-left: 1.5em;
  }

  ol > li,
  ul > li {
    list-style-type: none;
  }

  ul > li::before {
    content: "\2022";
  }

  li::before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em;
  }

  li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    margin-right: 0.3em;
    text-align: right;
  }

  li.ql-direction-rtl::before {
    margin-left: 0.3em;
    margin-right: -1.5em;
  }

  ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
    counter-increment: list-0;

    &::before {
      content: counter(list-0, decimal) ". ";
    }

    &.ql-indent-1 {
      counter-increment: list-1;

      &::before {
        content: counter(list-1, lower-alpha) ". ";
      }

      counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-2 {
      counter-increment: list-2;

      &::before {
        content: counter(list-2, lower-roman) ". ";
      }

      counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-3 {
      counter-increment: list-3;

      &::before {
        content: counter(list-3, decimal) ". ";
      }

      counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-4 {
      counter-increment: list-4;

      &::before {
        content: counter(list-4, lower-alpha) ". ";
      }

      counter-reset: list-5 list-6 list-7 list-8 list-9;
    }

    &.ql-indent-5 {
      counter-increment: list-5;

      &::before {
        content: counter(list-5, lower-roman) ". ";
      }

      counter-reset: list-6 list-7 list-8 list-9;
    }

    &.ql-indent-6 {
      counter-increment: list-6;

      &::before {
        content: counter(list-6, decimal) ". ";
      }

      counter-reset: list-7 list-8 list-9;
    }

    &.ql-indent-7 {
      counter-increment: list-7;

      &::before {
        content: counter(list-7, lower-alpha) ". ";
      }

      counter-reset: list-8 list-9;
    }

    &.ql-indent-8 {
      counter-increment: list-8;

      &::before {
        content: counter(list-8, lower-roman) ". ";
      }

      counter-reset: list-9;
    }

    &.ql-indent-9 {
      counter-increment: list-9;

      &::before {
        content: counter(list-9, decimal) ". ";
      }
    }

    &.ql-direction-rtl {
      padding-right: 1.5em;

      &::before {
        margin-right: -1.5em;
      }
    }

    &:not(.ql-direction-rtl) {
      padding-left: 1.5em;

      &::before {
        margin-left: -1.5em;
      }
    }
  }
}

.bricks--container .bricks--section {
  padding: 0 !important;
}

.bricks--container p {
  line-height: 1.5 !important;
}

#rd-column-m02ytoou p {
  margin: 0 0 20px;
}

#testimonials-section {
  padding: 50px 0;
  background-color: #28061a;
  color: #fff;
}

.testimonials-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center; /* Centraliza verticalmente os botões */
}

.testimonial-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 90%; /* Ajusta para caber na largura do container */
}

.testimonial-slide {
  padding: 20px;
  box-sizing: border-box;
  width: 33.33%; /* 3 cards em telas maiores */
  flex-shrink: 0;
  display: flex; /* Força todos os cards a ter a mesma altura */
  align-items: center; /* Alinha verticalmente o conteúdo */
  justify-content: center; /* Centraliza o conteúdo */
}

.testimonial_box-inner {
  display: flex;
  flex-direction: column;
  height: 100%; /* Garante que o conteúdo ocupe toda a altura do card */
}

.testimonial_box-top {
  flex: 1; /* Ocupa a maior parte do espaço do card */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Garante que o texto e o nome fiquem em posições fixas */
}

.testimonial_box {
  background-color: #e8e8e8;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 2px 2px 10px #742d56;
  width: 100%; /* Garante que todos os cards ocupem a mesma largura */
  height: 25rem; /* Garante que todos os cards ocupem a mesma largura */
  max-width: 300px; /* Define uma largura máxima para evitar cards muito grandes */
}

.testimonial_box-icon {
  padding: 10px 0;
  i {
    font-size: 25px;
    color: #14213d;
  }
}

.testimonial_box-text {
  flex: 8; /* O texto ocupará 80% do espaço */
  padding: 10px 0;
  p {
    color: #293241;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
}

.testimonial_box-name {
  flex: 1; /* O nome ocupará 10% do espaço */
  padding-top: 10px;
  h4 {
    font-size: 20px;
    line-height: 25px;
    color: #293241;
    margin: 0; /* Remove qualquer margem */
    text-align: center; /* Centraliza o nome no espaço */
  }
}

/* Responsividade para telas grandes */
@media (max-width: 1200px) {
  .testimonial-slide {
    width: 50%; /* Dois cards em tablets */
  }
}

/* Responsividade para tablets */
@media (max-width: 768px) {
  .testimonial-slide {
    width: 100%; /* Um card por vez em telas menores */
  }
}

/* Responsividade para celulares */
@media (max-width: 576px) {
  .testimonial-slide {
    flex: 0 0 100%; /* O card ocupará 100% da largura da tela */
    max-width: 100%; /* Garante que o card não ultrapasse a tela */
    margin: 0 auto; /* Centraliza o card */
    padding: 10px; /* Ajusta o padding interno */
    box-sizing: border-box; /* Inclui padding e borda no cálculo de largura */
  }

  .testimonial-container {
    overflow-x: hidden; /* Garante que não haja rolagem lateral */
    display: flex;
    justify-content: center; /* Centraliza o card */
  }

  .testimonial_box {
    width: 80%; /* Ocupa 100% da largura disponível */
    box-sizing: border-box; /* Inclui padding e borda no cálculo da largura */
    margin: 0 auto; /* Centraliza o card */
    padding: 10px 10px; /* Ajusta o padding interno */
  }
}

/* Botões de navegação SVG */
.testimonial-nav svg {
  fill: #e8e8e8; /* Cor branca para os SVGs */
  width: 3.5rem; /* Largura padrão para telas maiores, baseando-se em rem */
  height: 3.5rem;
  transition: transform 0.2s, fill 0.2s;
}

.testimonial-nav {
  background: none; /* Remove o fundo dos botões */
  border: none; /* Remove as bordas dos botões */
  cursor: pointer;
  padding: 1rem; /* Usar rem também para espaçamento */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

#prev-btn {
  left: 1rem; /* Usa rem para espaçamento da borda */
}

#next-btn {
  right: 1rem; /* Usa rem para espaçamento da borda */
}

/* Responsividade para diferentes tamanhos de tela */
@media (max-width: 1200px) {
  .testimonial-nav svg {
    width: 3rem; /* Reduz tamanho em telas médias */
    height: 3rem;
  }
}

@media (max-width: 768px) {
  .testimonial-nav svg {
    width: 2.5rem; /* Reduz ainda mais em tablets */
    height: 2.5rem;
  }
}

/* Adiciona efeito de hover */
.testimonial-nav:hover svg {
  fill: #cccccc; /* Altera a cor ao passar o mouse */
  transform: scale(1.1); /* Leve aumento ao passar o mouse */
}
