/* Estilos Gerais do Cabeçalho */
#primeira-secao {
  text-align: center;
  color: #fff;
  background-attachment: scroll;
  background-image: url($header-image); /* Substitua pelo caminho correto da sua imagem */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 80vh;
  max-height: 800px;
}

/* Sobreposição (Overlay) para melhorar a legibilidade do texto */
#primeira-secao::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(101, 15, 64, 0.3); /* Ajustado para um tom mais suave de #650F40 com opacidade reduzida */
  z-index: 0;
}

/* Estilos do Texto dentro do Cabeçalho */
#primeira-secao .intro-text {
  padding-top: 100px;
  padding-bottom: 50px;
  position: relative;
  z-index: 1; /* Isso garante que o texto fique acima da sobreposição */
}

#primeira-secao .intro-text .intro-lead-in {
  margin-bottom: 25px;
  font-family: $font-primary;/* Substitua pela sua fonte */
  font-size: 22px;
  font-style: italic;
  line-height: 22px;
}

#primeira-secao .intro-text .intro-heading {
  margin-bottom: 25px;
  font-family: $font-title; /* Substitua pela sua fonte */
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
}

/* Ajustes para Telas Maiores (por exemplo, tablets e desktops) */
@media (min-width: 768px) {
  #primeira-secao .intro-text {
    padding-top: 250px;
    padding-bottom: 150px;
  }

  #primeira-secao .intro-text .intro-lead-in {
    font-size: 40px;
    line-height: 40px;
    text-align: right;
  }

  #primeira-secao .intro-text .intro-heading {
    font-size: 45px;
    line-height: 75px;
    text-align: right;
  }
}
